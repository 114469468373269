<template>
  <div class="sideBar px-3 box-shadow bg-white">
    <button @click="resetFilter" class="btn primary-button text-white py-1">
      Clear all filters
    </button>
    <h6 class="my-3 secondaryColor border-bottom pb-3">Filters</h6>
    <div class="mt-4">
      <!-- Employee -->
        <div class="card shadow-none border-bottom pb-2 py-3" v-if="!isTeamMember()">
          <a
            href="javascript: void(0);"
            v-b-toggle.faqs-gen-ques-empl
            class="text-dark"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="faqs-gen-ques-empl"
          >
            <div class="border-0">
              <div class="media align-items-center">
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 fw-medium mb-1 fw-bold">Employees</h5>
                </div>
                <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
              </div>
            </div>
          </a>

          <b-collapse
            id="faqs-gen-ques-empl"
            visible
            accordion="faqs-accordion"
            data-parent="#faqs-accordion"
          >
            <div class="">
              <vue-multiselect
                placeholder="Select an Employee"
                v-model="sideBarData.employee"
                :load-more="loadMoreEmployees"
                :loading="employees.loading"
                :options="employees.data"
                @on-open="fetchAllMembers"
                @on-reaching-end="fetchEmployeesOnReachingEnd"
                :id="'employees'"
                internal-search
                showNoResult
                label="name"
              >
              </vue-multiselect>
            </div>
          </b-collapse>
        </div>

        <!-- <div
          class="card shadow-none border-bottom pb-2" v-if="!isTeamMember()"
          >
          <a
            href="javascript: void(0);"
            v-b-toggle.faqs-gen-roles
            class="text-dark"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="faqs-gen-roles"
          >
            <div class="border-0">
              <div class="media align-items-center">
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 fw-medium mb-1 fw-bold">Roles</h5>
                </div>
                <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
              </div>
            </div>
          </a>
          <b-collapse
            id="faqs-gen-roles"
            visible
            accordion="faqs-accordion"
            data-parent="#faqs-accordion"
          >
            <vue-multiselect
              v-model="sideBarData.role"
              hide-selected
              :loading="roles.loading"
              :options="roles.data"
              @on-open="fetchAreas"
              @on-reaching-end="fetchAreasOnReachingEnd"
              :id="'roles'"
              label="name"
              internal-search
              showNoResult
            >
            </vue-multiselect>
          </b-collapse>
        </div> -->

        <div class="card shadow-none border-bottom pb-2">
            <!-- ticket_status Section -->
            <div class="availability  border-2 py-2">
                <p class="f-14 mb-2 fw-bold">Ticket Status</p>
                <div  v-for="(status, index) in ticket_status" :key="index">
                <div class="form-check form-switch mb-2" >
                    <input
                    class="form-check-input"
                    v-model="sideBarData.ticket_status"
                    type="radio" 
                    :value="status.value"
                    :id="status.value"
                    />
                    <label class="form-check-label fw-normal" for="fullTime"
                    >{{status.label}}</label
                    >
                </div>
                </div>
            </div>
            <!--end of ticket_status Section -->
        </div>

        <div class="card shadow-none border-bottom pb-2">
            <!-- ticket_prority Section -->
            <div class="availability  border-2 py-2">
                <p class="f-14 mb-2 fw-bold">Ticket Priority</p>
                <div  v-for="(priority, index) in ticket_prority" :key="index">
                <div class="form-check form-switch mb-2" >
                    <input
                    class="form-check-input"
                    v-model="sideBarData.ticket_prority"
                    type="radio" 
                    :value="priority.value"
                    :id="priority.value"
                    />
                    <label class="form-check-label fw-normal" for="fullTime"
                    >{{priority.label}}</label
                    >
                </div>
                </div>
            </div>
            <!--end of ticket_prority Section -->
        </div>

    </div>
  </div>
</template>
<script>
import VueMultiselect from "@/components/general/vue-multiselect";
import _ from "lodash";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

const filter_Prototype = {
    ticket_status : null,
    ticket_prority : null,
    location: null,
    created_by: null,
};
export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
        params: {},
        managers: [],
        location: "All",

        roles: {
          data: [],
          selected: [],
          VueMultiselectOpened: false,
          loading: false,
        },

        employees: {
          data: [],
          selected: [],
          VueMultiselectOpened: false,
          loading: false,
          nextPageUrl: null,
        },
        sideBarData: {
            ...filter_Prototype,
        },
        ticket_status: [
            { value: 'All', label: 'Show All' },
            { value: 'Open', label: 'Open' },
            { value: 'in Progress', label: 'Inprogress' },
            { value: 'Resolved', label: 'Resolved' },
            { value: 'Closed', label: 'Closed' },
        ],
        ticket_prority: [
            { value: 'All', label: 'Show All' },
            { value: 'Low', label: 'Low' },
            { value: 'Normal', label: 'Normal' },
            { value: 'High', label: 'High' },
            { value: 'Emergency', label: 'Emergency' },
        ],
    };
  },
  watch: {
    sideBarData: {
      handler: function (newData) {
        this.$emit("filterData", newData);
      },
      deep: true,
    },
  },
  computed: {
    loadMoreEmployees() {
      return !!this.employees.nextPageUrl;
    },
  },
  methods: {
    fetchAreas() {
      let url = "/restaurant/area/role/index";
      this.$axios
        .get(url, { params: this.params })
        .then((response) => {
          let result = response.data.payload.paginated;
          if (url == "/restaurant/area/role/index") {
            this.roles.data = [];
          }
          //add roles
          result.data.forEach((element) => {
            this.roles.data.push(...element.area_role);
          });
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },
    resetFilter() {
      this.sideBarData = {
        ...filter_Prototype,
      };
      this.$emit("filterData", { clearFilters: true });
    },

    fetchEmployeesOnReachingEnd() {
      this.fetchAllMembers();
    },

    fetchAreasOnReachingEnd() {
      this.fetchAreas();
    },

    fetchAllMembers() {
    let url = "/employee/team-member";
    if (this.employees.nextPageUrl) {
      url = this.employees.nextPageUrl;
    }
    this.$axios
      .get(url, {
        // params: { employee_status: "active_employee" },
      })
      .then((response) => {
        let result = response.data.payload.paginated;
        if (url == "/employee/team-member") this.employees.data = [];
        this.employees.data.push(...result.data);
        this.employees.nextPageUrl = result.next_page_url;
      })
      .catch((error) => {
        this.handleErrorResponse(error.response, "error");
      })
      .finally(function () {});
    },

  },
  mounted() {
    // this.fetchLocations();
    // this.fetchManagers();
  },
};
</script>