<!-- @format -->

<script>
import Layout from "@/views/layouts/static/main";
import EmployeeLayout from "@/views/pages/employee/layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import pagination from "@/views/pages/admin/calendar/reuseableComponents/pagination.vue";
import sidebar from "./components/sidebar.vue";
import AlertWidget from "@/components/general/AlertWidget";
import Swal from "sweetalert2";
import imageUrl from "@/assets/images/dashboard/default-avatar.jpg";
/**
 * Add-product component
 */
export default {
  page: {
    title: "Tickets List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    EmployeeLayout,
    PageHeader,
    pagination,
    sidebar,
    AlertWidget,
  },

  data() {
    return {
      title: "Tickets List",
      tickets: [],
      table_data: {
        length: 10,
      },
      paginationData: null,
      currentLayout: "Layout",
      isClick: false,
    };
  },

  methods: {
    listenFromSideBar(newData) {
      if (newData?.employee) {
        if (newData?.employee == "All") {
          this.table_data.user_id = null;
        } else {
          this.table_data.user_id = newData.employee.id;
        }
      }

      if (newData?.role) {
        this.table_data.area_role_id = newData?.role?.id;
      }

      if (newData?.ticket_status) {
        if (newData?.ticket_status == "All") {
          this.table_data.ticket_status = null;
        } else {
          this.table_data.ticket_status = newData.ticket_status;
        }
      }

      if (newData?.ticket_prority) {
        if (newData?.ticket_prority == "All") {
          this.table_data.ticket_prority = null;
        } else {
          this.table_data.ticket_prority = newData.ticket_prority;
        }
      }

      if (newData?.clearFilters) {
        this.table_data = {};
      }

      if (newData != null) {
        this.fetchTickets();
      }
    },
    fetchTickets(url) {
      url = url ?? "/ticket/index";
      if (this.currentUser.type == "team_member") {
        let userParam = { user_id: this.currentUser.id };
        this.table_data = {
          ...userParam,
          ...this.table_data,
        };
      }
      this.$axios
        .get(url, { params: this.table_data })
        .then((response) => {
          let data = response.data.payload;
          this.paginationData = data;
          this.tickets = data.data;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },
    deleteTicket(ticket_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "This process will not be revert",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, Accept",
      }).then((result) => {
          if (result.value) {
            this.$axios.delete("ticket/delete/" + ticket_id).then((response) => {
              this.tickets = this.tickets.filter((item) => item.id != ticket_id);
              this.triggerSwal(response.data.message, "success");
            })
            .catch((error) => {
              this.handleErrorResponse(error.response, "error");
            });
          }
      });
      
    },
    roleToString(roles) {
      let role_array = roles.map((role) => {
        return role.name;
      });
      let string = role_array.toString();
      return string;
    },
  },
  mounted() {
    this.fetchTickets();
    if (this.isTeamMember()) {
      this.currentLayout = "EmployeeLayout";
    }
  },
  created() {
    this.imageUrl = imageUrl
  },
};
</script>

<template>
  <component :is="currentLayout">
    <div class="row" :class="{'mt-5' : currentLayout== 'EmployeeLayout'}">
      <div
        class="col-xl-2 ps-0 position-relative transition_class"
        :class="{ fullWidth: isClick }"
      >
        <sidebar ref="sideBar" @filterData="listenFromSideBar" />
        <i
          class="fas position-absolute top-0 end-0 px-4 f-16 primaryColor pointer"
          @click="isClick = !isClick"
          :class="isClick == false ? 'fa-times' : 'fa-bars'"
        >
        </i>
      </div>

      <div class="col-xl-10" :class="{ 'col-xl-12': isClick }">
        <div class="row">
          <div class="col-md-6">
            <PageHeader :title="title" />
            <p  v-if="isRestaurantOwner()">
				<span class="fw-bold"> Note :</span>
				<span class="primaryColor"> Admin can't edit ticket created by employee or manager</span>
            </p>
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-end">
              <div class="dropdown-btn">
                <router-link to="/add-ticket">
                  <button class="btn text-white">Add Ticket</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row col-12">
              <div class="col-md-12 mb-0">
                <div class="my-3 table-nowrap table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="text-capitalize text-center">ticket</th>
                        <th class="text-capitalize text-center">title</th>
                        <th class="text-capitalize text-center">user name</th>
                        <th class="text-capitalize text-center">last update</th>
                        <th class="text-capitalize text-center">role</th>
                        <th class="text-capitalize text-center">priority</th>
                        <th class="text-capitalize text-center">status</th>
                        <th class="text-capitalize text-center">actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(ticket, index) in tickets" :key="index">
                        <td class="align-middle text-center">
                          {{ ticket.id }}
                        </td>
                        <td class="align-middle text-center">
                          {{ ticket.topic }}
                        </td>
                        <td class="" v-if="ticket.user">
                          <span>
                            <img
                              :src="
                                ticket.user.avatar == null
                                  ? ticket.user.avatar_path
                                  : assets_url + ticket.user.avatar
                              "
                              class="avatar-xs rounded-circle me-2"
                            />
                          </span>
                          <span>{{ ticket.user.name }}</span>
                        </td>
                        <td class="" v-else>
                          <img
                              :src="imageUrl"
                              class="avatar-xs rounded-circle me-2"
                            />
                          <span>{{ticket.name}} (Deleted User)</span>
                        </td>
                        <td class="align-middle text-center">
                          {{ DateWithMonthName(ticket.updated_at) }}
                        </td>
                        <td
                          class="align-middle text-center"
                          v-if="ticket.user && ticket.user.type != 'restaurant_owner'"
                        >
                          {{ roleToString(ticket.user.employee.area_roles) }}
                        </td>
                        <td class="align-middle text-center" v-else>N/A</td>
                        <td class="align-middle text-center">
                          <span
                            class="p-1 px-3 radius-20 align-middle"
                            :class="[
                              ticket.priority === 'Low'
                                ? 'text-success bg-soft-success'
                                : '',
                              ticket.priority === 'Emergency'
                                ? 'text-danger bg-soft-danger'
                                : '',
                              ticket.priority === 'High'
                                ? 'text-warning bg-soft-warning'
                                : '',
                              ticket.priority === 'Normal'
                                ? 'text-info bg-soft-info'
                                : '',
                            ]"
                            >{{ ticket.priority }}</span
                          >
                        </td>
                        <td class="align-middle text-center">
                          <span class="status-style py-1 px-3 rounded-pill">{{
                            ticket.status
                          }}</span>
                        </td>

                        <td class="align-middle text-center">
                          <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                              <router-link
                                title="view"
                                :to="{
                                  name: 'ticket-detail',
                                  params: { ticket_id: ticket.id },
                                }"
                              >
                                <button
                                  title="View"
                                  class="btn btn-sm ratio-4x3 secondary-button text-bg-danger mx-2"
                                >
                                  <i
                                    class="uil uil-eye font-size-18 text-white"
                                  ></i>
                                </button>
                              </router-link>
                              <button
                                class="btn btn-sm ratio-4x3 secondary-button text-bg-danger"
                                :disabled="ticket.user_id !== currentUser.id || ticket.status == 'Closed'"
                                title="Edit"
                              >
                                <router-link
                                  title="Edit"
                                  :to="{
                                    name: 'edit-ticket',
                                    params: { ticket_id: ticket.id },
                                  }"
                                >
                                  <i
                                    class="uil uil-pen font-size-18 text-white"
                                  ></i>
                                </router-link>
                              </button>
                              <button
                                title="Delete"
                                class="mx-2 btn btn-sm ratio-4x3 secondary-button text-bg-danger"
                                :disabled="ticket.user_id !== currentUser.id || ticket.status == 'Closed'"
                                @click="deleteTicket(ticket.id)"
                              >
                                <i
                                  class="uil uil-trash font-size-18 text-white"
                                ></i>
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="tickets.length == 0">
                  <AlertWidget
                    class="mt-4"
                    text="No Record Found"
                  ></AlertWidget>
                </div>
              </div>
            </div>
            <div class="row">
              <pagination
                :paginationProp="paginationData"
                @next="fetchTickets"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>
